import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import router from './router'
import * as echarts from 'echarts';
Vue.config.productionTip = false
Vue.use(Antd);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import { Icon, Popup, Picker } from 'vant';
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Picker);

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.prototype.$echarts = echarts
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')
