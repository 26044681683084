import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/s'
    },
    {
      path: '/s',
      component: resolve => require(['@/views/product_detail'], resolve),
      meta:{ title: '产品信息' }
    },
    {
      path: '/af',
      component: resolve => require(['@/views/add_feedback'], resolve),
      meta:{ title: '报障报修' }
    },
    {
      path: '/h',
      component: resolve => require(['@/views/history'], resolve),
      meta:{ title: '维修记录' }
    },
    {
      path: '/q',
      component: resolve => require(['@/views/question'], resolve),
      meta:{ title: '常见问题' }
    },
    {
      path: '/qd',
      component: resolve => require(['@/views/question_detail'], resolve),
      meta:{ title: '常见问题详情' }
    },
    {
      path: '/apf',
      component: resolve => require(['@/views/add_product_feedback'], resolve),
      meta:{ title: '报障报修' }
    },
    {
      path: '/e/:id',
      component: resolve => require(['@/views/evaluate'], resolve),
      meta:{ title: '评价' }
    },
    {
      path: '/fh',
      component: resolve => require(['@/views/feedback_history'], resolve),
      meta:{ title: '售后进度' }
    },
    {
      path: '/b',
      component: resolve => require(['@/views/board'], resolve),
      meta:{ title: '概要看板' }
    },
    {
      path: '/d',
      component: resolve => require(['@/views/device'], resolve),
      meta:{ title: '设备信息' }
    },
    {
      path: '/iph',
      component: resolve => require(['@/views/inspection_plan_history'], resolve),
      meta:{ title: '巡检历史' }
    },
    {
      path: '/doh',
      component: resolve => require(['@/views/device_order_history'], resolve),
      meta:{ title: '维修历史' }
    },
    {
      path: '/ado',
      component: resolve => require(['@/views/add_device_order'], resolve),
      meta:{ title: '新增设备维修' }
    }
  ]
})
